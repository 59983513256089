<template>
	<!-- 派车补量单 -->
	<div :class="themeClass" class="padding-b-20">
		<div class="flex-row margin-b-5">
			您的位置：
			<breadcrumb></breadcrumb>
		</div>
		<div class="increase border-F2F2F2">
			<div class="title font-size16 font-weight700">派车补量单</div>
			<div class="content font-size14">
				<div class="form">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px" label-position="left" class="demo-ruleForm">
						<el-form-item label="商品名称">
							<el-input v-model="ruleForm.fGoodsFullName" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="派车编码">
							<el-input v-model="ruleForm.fDeliveryOrderNumber" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="订单编码">
							<el-input v-model="ruleForm.fOrderNumber" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="原派车量">
							<el-input v-model="ruleForm.fScheduleVanAmount" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="补量(吨)" prop="fAddAmount">
							<el-input v-model="ruleForm.fAddAmount" @blur="inputBlur" :max="maxAmountLimit" type="number"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div class="tips padding-tb-15 padding-lr-20">
					<div class="title">温馨提示</div>
					<div class="tip-content font-color-666">
						1.补量只能输入整数吨位;<br />2.补量不能大于系统设置的最大补量{{maxAmountLimit}}吨。
					</div>
				</div>
				<div @click="submitForm" class="pointer btn background-color-theme font-size14 text-center">
					提交
				</div>
			</div>
		</div>
		<el-dialog title="补量" :visible.sync="dialogVisible" width="536px" :before-close="handleClose">
			<div class="flex-row-align-center">
				<div class="replenishment-image-box">
					<el-image style="width: 100%;" :src="url" :fit="'contain'"></el-image>
				</div>
				<div style="margin-left: 20px;">
					<div style="margin-bottom: 10px;" class="color-theme font-size20 font-weight700">提交成功</div>
					<div style="margin-bottom: 30px;" class="font-color-666 font-size13">补量申请已提交，请尽快完成支付！</div>
					<div class="go-button flex-row-center-center background-color-theme" @click="goPay">去支付</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	import breadcrumb from "@/components/breadcrumb.vue";

	export default {
		name: "",
		components: {
			breadcrumb
		},
		props: {},
		data() {
			return {
				// 派车订单ID
				fDeliveryOrderID: null,
				ruleForm: {
					fGoodsFullName: "",
					fDeliveryOrderNumber: "",
					fOrderNumber: "",
					fScheduleVanAmount: "",
					fAddAmount: null,
				},
				// 最大可补量
				maxAmountLimit: 0,
				rules: {
					fAddAmount: [{
							required: true,
							message: "补量为必填项",
							trigger: "blur"
						},
						{
							validator: (rule, value, callback) => {
								let num = parseInt(this.maxAmountLimit)
								if (value > num) {
									callback(new Error('补量不可大于' + num));
								} else {
									callback();
								}
							},
							trigger: "blur",
						},
					],
				},
				dialogVisible: false,
				url: require('@/assets/sendCar/buliang.png')
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		mounted() {
			this.fDeliveryOrderID = this.$store.getters.getCommonData.id;
			// 获取派车信息接口
			this.getDeliverOrder()
			// 获取最大可排量
			this.getOrderAddAmountLimit();
		},
		// activited() {
		// 	console.log("activited");
		// 	this.fDeliveryOrderID = this.$store.getters.getCommonData.id;
		// 	// 获取派车信息接口
		// 	this.getDeliverOrder()
		// 	// 获取最大可排量
		// 	this.getOrderAddAmountLimit();
		// },
		methods: {
			inputBlur() {
				this.ruleForm.fAddAmount = parseInt(this.ruleForm.fAddAmount)
			},
			// 
			// 获取派车信息接口
			getDeliverOrder() {
				this.ApiRequestPostNOMess("api/mall/ebsale/deliver-order/get", {
					id: this.fDeliveryOrderID.toString()
				}).then(res => {
					this.ruleForm.fGoodsFullName = res.obj.fGoodsFullName
					this.ruleForm.fDeliveryOrderNumber = res.obj.fDeliveryOrderNumber
					this.ruleForm.fOrderNumber = res.obj.fOrderNumber
					this.ruleForm.fScheduleVanAmount = res.obj.fScheduleVanAmount
				})
			},
			// 获取最大可排量
			getOrderAddAmountLimit() {
				this.ApiRequestPostNOMess("api/mall/ebsale/order-add-record/get-order-add-amount-limit", {
					fDeliveryOrderID: this.fDeliveryOrderID.toString()
				}).then(res => {
					this.maxAmountLimit = res.obj.day
				})
			},
			submitForm() {
				this.$refs["ruleForm"].validate((valid) => {
					if (!valid) {
						return false;
					}
					this.ApiRequestPostNOMess("api/mall/ebsale/order-add-record/create", {
						fDeliveryOrderID: this.fDeliveryOrderID.toString(),
						fAddAmount: this.ruleForm.fAddAmount
					}).then(res => {
						if (res.obj.status == true) {
							this.tipsSuccess(res.obj.message)
							this.dialogVisible=true;
						} else {
							this.tipsInfo(res.obj.message)
						}
					})
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			handleClose() {
				this.dialogVisible = false
			},
			goPay(){
				this.$router.replace({
					path: '/businessme/CanPayReplenished',
				});
				this.handleClose();
			}
		}
	};
</script>

<style lang="scss" scoped>
	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	/deep/ .el-input.is-disabled .el-input__inner {
		background-color: #fff;
		color: #000;
	}

	.increase {
		max-width: 1100px;
		min-width: 1100px;
		margin: 0 auto;
		height: 592px;
		box-sizing: border-box;
		padding-left: 10px;

		.title {
			height: 51px;
			line-height: 51px;
		}

		.content {
			width: 412px;
			margin-left: 24px;
		}

		.form {
			/deep/ .el-form-item {
				height: 32px;
				margin-bottom: 28px;
			}

			/deep/ .el-form-item__content {
				height: 32px;
				line-height: 32px;
			}

			/deep/ .el-input__inner {
				width: 330px;
				height: 32px;
				line-height: 32px;
				padding: 0 6px;
			}

			/deep/ .increase .form[data-v-175c870d] .el-input {
				height: 40px;
			}

			/deep/ .el-form-item__label {
				height: 32px;
				line-height: 32px;
			}
		}

		.tips {
			height: 103px;
			background: #f2f4fd;
			box-sizing: border-box;

			.title {
				height: 19px;
				line-height: 19px;
				color: #ff6600;
				margin-bottom: 6px;
			}

			.tip-content {
				line-height: 22px;
				margin-bottom: 4px;
			}
		}

		.btn {
			width: 235px;
			height: 45px;
			line-height: 45px;
			margin-top: 35px;
		}
	}

	.replenishment-image-box {
		width: 220px;
		height: 200px;
	}

	::v-deep .el-dialog__body {
		padding-top: 0px;
	}

	.go-button {
		width: 160px;
		height: 40px;
	}
</style>
